<template>
    <aside class="card card-f-h flex flex-1 flex-col">
        <header class="border-b border-solid border-gray-300 pb-4">
            <h1>{{ $t('communication.inbox') }}</h1>
        </header>
        <div
            v-for="subject in subjectsData"
            :key="subject.uuid"
            class="flex p-3 hover:bg-gray-100 border-b border-solid border-gray-300 pb-4 mt-5 rounded-lg transition cursor-pointer"
            :class="{ 'bg-blue-50': subject.status === 0 }"
            @click="emailSelection(subject.uuid)"
        >
            <div class="flex flex-col pt-1 mr-3 w-5">
                <fa-icon v-if="subject.vip" :icon="['fas', 'star']" fixed-width class="text-yellow-300 mb-2" />
                <fa-icon v-if="subject.complain" :icon="['fas', 'angry']" fixed-width class="text-red-500" />
            </div>
            <div class="flex-1">
                <p class="mb-2 font-semibold text-sm">
                    {{ subject.subject }}
                </p>
                <p class="text-xs">
                    {{ subject.sender_name }} <span class="text-gray-400 font-hairline">({{ subject.sender_email }})</span>
                </p>
            </div>
            <div class="flex flex-col justify-between text-sm">
                <p class="text-right text-xs">
                    {{ subject.created_at }}
                </p>
                <p class="text-xs text-gray-400 font-hairline">
                    {{ $t('communication.to') }}: {{ subject.receiver_email }}
                </p>
            </div>
        </div>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </aside>
</template>
<script>
export default {
    props: {
        selectedEmailUuid: {
            type:    String,
            default: null,
        },
        inboxType: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            subjectsData: [],
        };
    },

    watch: {
        selectedEmailUuid() {
            if (this.selectedEmailUuid) return;
            this.getAll();
        },
        inboxType() {
            this.getAll();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_emails');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            const inboxType = {
                inbox_from_clients: 'in_clients',
                inbox_from_others:  'in_others',
                sent:               'out',
                deleted:            'deleted',
            };
            this.$wait.start('loading.subjects');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/communication/emails`, {
                    params: {
                        inbox_type: inboxType[this.inboxType],
                    },
                });
                this.subjectsData = Object.freeze(data.data);
                this.$wait.end('loading.subjects');
                this.$wait.end('loading.initial_emails');
            } catch (error) {
                this.$wait.end('loading.subjects');
                this.$wait.end('loading.initial_emails');
            }
        },

        emailSelection(uuid) {
            this.$emit('selected', uuid);
        },
    },
};
</script>
